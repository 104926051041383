<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12 text-left pl-1 pb-3>
        <span class="bold"> <h3>USER VIEW</h3></span>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-card width="100%" tile>
          <v-layout wrap pa-3 pa-sm-5>
            <v-flex xs12 sm6 class="subhead">
              <v-layout wrap>
                <v-flex xs12>
                  <span class="sbold"> User Name :</span> &nbsp;
                  <span class="regular">
                    {{ user.firstName }} {{ user.lastName }}</span
                  >
                </v-flex>
                <v-flex xs12>
                  <span class="sbold"> phone :</span> &nbsp;
                  <span class="regular"> {{ user.phone }}</span>
                </v-flex>
                <v-flex xs12>
                  <span class="sbold"> Email :</span> &nbsp;
                  <span class="regular"> {{ user.email }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="subhead"
              py-2
              v-if="totalLength == 0"
              text-center
              align-self-center
            >
              <span class="bold"> No Uploads Yet.....</span>
            </v-flex>
            <v-flex
              xs12
              sm6
              class="subhead"
              py-2
              v-else
              text-center
              align-self-center
            >
              <h4 style="text-transform: uppercase">Total Number of Reports</h4>
              <span class="bold" style="color: green; font-size: 30px">{{
                totalLength
              }}</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <v-layout wrap>
      <v-flex xs12>
        <div id="map456"></div>
      </v-flex>
    </v-layout> -->
    <v-layout wrap pt-5>
      <v-flex xs12 text-left pb-3>
        <span style="text-transform: uppercase" class="bold" large>
          <h3>Reports</h3></span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="records"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:[`item.create_date`]="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <v-btn
              x-small
              block
              color="blue"
              @click="$router.push('uploaddetail?id=' + item._id)"
              ><span title="view" style="color: white">VIEW</span></v-btn
            ></template
          >
          <template v-slot:[`item._id`]="{ item }">
            <v-dialog persistent v-model="item.delete" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" v-on="on" v-bind="attrs" title="delete">
                  mdi-delete
                </v-icon>
              </template>
              <v-card>
                <v-card-title
                  >Are you sure you want to delete this report ?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="deleteItem(item)"
                    >OK</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="item.delete = false"
                    >Cancel</v-btn
                  >

                  <v-spacer></v-spacer>
                  <v-flex xs3 sm3 lg1 pa-2 align-self-center text-center>
                    <router-link
                      :to="'/userview?id=' + item._id"
                      style="text-decoration: none"
                    >
                      <!-- <v-icon color="primary"> mdi-eye </v-icon> -->
                      <v-btn color="primary" dark x-small block> VIEW </v-btn>
                    </router-link>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-2>
      <v-flex xs12>
        <v-pagination v-model="page" :length="pagelength"></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
export default {
  data() {
    return {
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,

      headers: [
        { text: "Date", value: "create_date" },
        {
          text: "Division ",
          align: "start",
          sortable: false,
          value: "divisionId.divisionName",
        },
        {
          text: "Range ",
          align: "start",
          sortable: false,
          value: "rangeId.rangeName",
        },
        {
          text: "Beat ",
          align: "start",
          sortable: false,
          value: "beatId.beatName",
        },
        // {
        //   text: "Comp ",
        //   align: "start",
        //   sortable: false,
        //   value: "compId.compName",
        // },
        { text: "No Of Members", sortable: false, value: "noOfParticipants" },
        // { text: "Active Sanre", sortable: false, value: "noOfActiveSnare" },
        // { text: "Removed Traps", value: "noOfTrapsRemoved" },
        // { text: " Not Removed Traps", value: "noOfTrapsNotRemoved" },
        {
          text: "No of Snare Quantity",
          sortable: false,
          value: "noOfSnareRemoved",
        },
        {
          text: "Distance Walked (m)",
          sortable: false,
          value: "distanceWalked",
        },
        { value: "id", align: "center" },
        { value: "_id", align: "center" },
      ],
      //map data
      latt: null,
      lonn: null,
    };
  },
  watch: {
    page() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    // this.getMap();
  },
  methods: {
    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/admin/delete/walk",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },
    rowClicked(item) {
      this.$router.push("/uploaddetail?id=" + item._id);
    },
    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("map456"), {
        center: { lat: 9.5260093, lng: 76.8144186 },
        zoom: 2,
      });
    },

    getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/allreportings",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 10,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.user;
            this.records = response.data.data;
            this.totalLength = response.data.totalLength;
            this.pagelength = Math.ceil(response.data.totalLength / 10);
            for (let i = 0; i < response.data.data.length; i++) {
              this.latt = response.data.data[i].startGps[1];
              this.lonn = response.data.data[i].startGps[0];
              // console.log("lat",response.data.data[i].location[1])
              // console.log("lon",response.data.data[i].location[0])

              this.icons = {
                // url: require("../../assets/images/whale.svg"),
                size: new google.maps.Size(46, 50),
                scaledSize: new google.maps.Size(32, 35),
                anchor: new google.maps.Point(0, 50),
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
              });

              const infowindow = new google.maps.InfoWindow({
                // content: String(
                //   "<h3>" +
                //     this.records[i].boatname +
                //     "</h3>" +
                //     '<a href="/uploaddetail?id=' +
                //     this.records[i]._id +
                //     '"><button class="RB " style="color:#d39c00;font-size:12px" >View Details</button></a>'
                // ),
                maxWidth: 500,
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
#map456 {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.v-data-table {
  font-family: pregular;
}
</style>
